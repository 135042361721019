/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'

import {
  Backdrop,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useForm } from 'react-hook-form'

import { SendIcon } from 'components/icons/personalizedIcons'
import InputField from 'components/InputField'
import Timeline from 'components/Timeline'
import useAuthContext from 'contextApi/AuthContext'
import { postNote } from 'services/Returns'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    width: '90%',
    maxWidth: '600px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4),
  },
}))

const EvidencesModal = ({ returnInfo, isOpen, handleClose }) => {
  const [comments, setComments] = useState([])
  const [detailNotes, setDetailNotes] = useState([])
  const classes = useStyles()
  const { handleSubmit, register, reset } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { userLoggedin } = useContext(useAuthContext)

  const onSubmit = (data) => {
    setIsLoading(true)
    postNote({
      returnId: returnInfo.returnData.returnId,
      returnDetailId: returnInfo.returnDetail.id,
      user: userLoggedin.firstName,
      description: data.note,
    })
      .then((res) => {
        if (res.isSuccessful) {
          const newNotes = res.data.map((note) => ({
            ...note,
            type: 'note',
          }))
          setDetailNotes(newNotes)
          reset()
        } else {
          console.log('Ha ocurrido un error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const combinedComments = [...comments, ...detailNotes].sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    return dateA - dateB
  })

  useEffect(() => {
    if (!isOpen || !returnInfo.returnDetail?.statusHistory) return
    const { detail, notes } = returnInfo.returnDetail
    const newStatusHistory = returnInfo.returnDetail.statusHistory.map((history) => ({
      ...history,
      type: 'state',
    }))
    newStatusHistory[0].description = detail
    const newNotes =
      notes?.map((note) => ({
        ...note,
        type: 'note',
      })) ?? []
    setComments(newStatusHistory)
    setDetailNotes(newNotes)
  }, [isOpen])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" style={{ color: 'black', margin: '25px auto 0' }}>
                Observaciones de la devolución
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <Close style={{ color: 'black' }} />
              </IconButton>
            </Grid>
          </Grid>
          <Timeline comments={combinedComments} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems="center" xs={12} justifyContent="space-between">
              <Grid item xs={11}>
                <InputField
                  label="Agregar comentario"
                  name="note"
                  ref={register}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item>
                <IconButton type="submit" disabled={isLoading}>
                  {isLoading ? <CircularProgress size={20} /> : <SendIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </Fade>
    </Modal>
  )
}

export default EvidencesModal
