/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react'

import {
  Backdrop,
  Fade,
  Modal,
  makeStyles,
  TextField,
  Button,
  withStyles,
  TextareaAutosize,
} from '@material-ui/core'

import { ExclamationIcon } from 'components/icons/personalizedIcons'

const useStyles = makeStyles((theme) => ({
  'label.Mui-focused': {
    color: 'green',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    width: '90%',
    maxWidth: '600px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnBackgroundErrorPriceSmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: '1px solid #6064ff',
    color: '#6064ff',
    fontWeight: '500',
    backgroundColor: '#ffffff',
    '&:hover': {
      color: '#FF40FC',
      border: '1px solid #FF40FC',
      backgroundColor: '#ffffff',
    },
  },
  btnNewProductsmall: {
    padding: '0.5rem 2rem',
    borderRadius: '50px',
    border: 'none',
    backgroundColor: '#5e44ff',
    color: 'white',
    fontWeight: '500',
    '&:hover': {
      fontWeight: 'bolder',
      backgroundColor: '#ef0bef',
      boxShadow: '-7px 7px 0px #000000',
      border: '2px solid #000000',
      borderRadius: '50px',
      opacity: '1',
    },
  },
}))

const DecitionsModal = ({ decition, onCheck, isOpen, handleClose, returnInfo }) => {
  const classes = useStyles()
  const [comment, setComment] = useState('')

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const handleCheck = () => {
    onCheck(returnInfo, decition, comment)
    handleClose()
  }

  useEffect(() => {
    if (!isOpen) return
    setComment('')
  }, [isOpen])

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }}>
              <ExclamationIcon />
            </div>
            <h2
              id="transition-modal-title"
              style={{ textAlign: 'center', fontSize: '26px', marginBottom: '24px' }}
            >
              ¡{decition ? 'Aprobación' : 'Rechazo'} de devolución!
            </h2>
            <p style={{ fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"' }}>
              Vas a {decition ? 'aprobar' : 'rechazar'} la devolución del producto "
              {returnInfo?.returnInfo.returnDetails[0].productName}" de la orden #
              {returnInfo?.returnInfo.orderNumber}.{' '}
              {returnInfo?.productStatusId !== 4 &&
                decition &&
                'Ten en cuenta que esta devolución no generará una guía de recogida para el producto en cuestión.'}
            </p>
            <TextareaAutosize
              maxLength={250}
              minRows={3}
              value={comment}
              style={{
                borderRadius: 6,
                minHeight: 150,
                maxHeight: 150,
                overflow: 'auto !important',
                width: '100%',
                padding: 10,
                border: '1px dashed #6064ff',
                marginTop: 10,
                resize: 'none',
              }}
              placeholder="Escriba el motivo de su decision"
              onChange={handleCommentChange}
            />
            <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={handleClose}
                color="secondary"
                variant="outlined"
                className={classes.btnBackgroundErrorPriceSmall}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleCheck}
                className={classes.btnNewProductsmall}
                color="primary"
                disabled={comment.length <= 0}
                variant="contained"
              >
                Confirmar
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default DecitionsModal
